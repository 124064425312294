.w-100pe{
    width: 100%;
}
.w-100pe{
    width: 100%;
}
.w-100pe{
    width: 100%;
}


.w-90pe{
    width: 90%;
    
}
.w-90pe{
    width: 80%;
}
.w-90pe{
    width: 70%;
}
.w-90pe{
    width: 60%;
}
.w-90pe{
    width: 50%;
}
.w-auto {
    width: auto;
}

.w-124px {
    width: 124px;
}

.w-79px {
    width: 79px;
}