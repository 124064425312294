.tex-transform-lowercase {
    text-transform: lowercase;
}

.tex-transform-uper {
    text-transform: uppercase;
}

.indented-paragraph-2 {
    text-indent: 2em;
}

.indented-paragraph-4 {
    text-indent: 4em;
}

.rt {
    color: red;
}

.normal-text {
    color: rgba(0, 0, 0, 0.88) !important;
    font-size: 14px !important;
    font-weight: 400;
}